$( document ).ready(function() {
    $(".animated").css("opacity", 0);


    $(".desktop-logo").waypoint(function() {
      $(".desktop-logo").addClass("fadeIn");
    }, { offset: "-5%" });

    $(".main-logo").waypoint(function() {
      $(".main-logo").addClass("fadeInLeft");
    }, { offset: "50%" });

    $(".main-title").waypoint(function() {
      $(".main-title").addClass("fadeInRight");
    }, { offset: "50%" });

    $("#every-start").waypoint(function() {
      $("#every-start").addClass("fadeInDown");
    }, { offset: "60%" });

    $(".text-history").waypoint(function() {
      $(".text-history").addClass("fadeInUp");
    }, { offset: "70%" });

    $(".text-info").waypoint(function() {
      $(".text-info").addClass("fadeInUp");
    }, { offset: "70%" });

    $(".text-services").waypoint(function() {
      $(".text-services").addClass("fadeInUp");
    }, { offset: "70%" });

    $(".list-services").waypoint(function() {
      $(".list-services").addClass("fadeInUp");
    }, { offset: "70%" });

    $(".list-services-inline").waypoint(function() {
      $(".list-services-inline").addClass("fadeInUp");
    }, { offset: "70%" });

    $(".fancy-1").waypoint(function() {
      $(".fancy-1").addClass("fadeInRight");
    }, { offset: "40%" });

    $(".fancy-2").waypoint(function() {
      $(".fancy-2").addClass("fadeInLeft");
    }, { offset: "40%" });

    $(".fancy-3").waypoint(function() {
      $(".fancy-3").addClass("fadeInRight");
    }, { offset: "40%" });

    $(".fancy-4").waypoint(function() {
      $(".fancy-4").addClass("fadeInLeft");
    }, { offset: "40%" });

    $(".contact-text").waypoint(function() {
      $(".contact-text").addClass("fadeInDown");
    }, { offset: "90%" });

    $(".images-row").waypoint(function() {
      $(".images-row").addClass("bounceInRight");
      $(".images-row").css("opacity", 1);
    }, { offset: "60%" });


  $(".change-img").click(function(e) {
    e.preventDefault();
    $(".change-img").removeClass("active");
    $(".list-services-inline li").removeClass("active");
    $(this).addClass("active");
    $(this).parent().addClass("active");
    var target = $(this).attr("data-target");
    switch(target) {
      case '1':
        $(".section-container-8").animate({opacity: 0}, 'slow', function() {
          $(this)
            .css({"background-image": "url(/assets/img/proyectos_empaque.jpg)"})
            .animate({opacity: 1});
          });
         break; 
      case '2':
        $(".section-container-8").animate({opacity: 0}, 'slow', function() {
          $(this)
            .css({"background-image": "url(/assets/img/proyectos_libros.jpg)"})
            .animate({opacity: 1});
          });
         break;
      case '3':
        $(".section-container-8").animate({opacity: 0}, 'slow', function() {
          $(this)
            .css({"background-image": "url(/assets/img/proyectos_revistas.jpg)"})
            .animate({opacity: 1});
          });
         break;
      case '4':
        $(".section-container-8").animate({opacity: 0}, 'slow', function() {
          $(this)
            .css({"background-image": "url(/assets/img/proyectos_armado.jpg)"})
            .animate({opacity: 1});
          });
         break;
      case '5':
        $(".section-container-8").animate({opacity: 0}, 'slow', function() {
          $(this)
            .css({"background-image": "url(/assets/img/proyectos_terminados.jpg)"})
            .animate({opacity: 1});
          });
         break;
      // case '6':
      //   $(".section-container-8").animate({opacity: 0}, 'slow', function() {
      //     $(this)
      //       .css({"background-image": "url(/assets/img/proyectos_terminadoss.jpg)"})
      //       .animate({opacity: 1});
      //     });
      //    break;

      default:
        $(".section-container-8").animate({opacity: 0}, 'slow', function() {
          $(this)
            .css({"background-image": "url(/assets/img/bg8.jpg)"})
            .animate({opacity: 1});
          }); 

    }

  });

  function rotateBanner()
  {

    if($('.section-container').hasClass('banner1')) {

      $('.section-container').animate({opacity: 0}, 'slow', function() {
        $(this).removeClass('banner1').addClass('banner2').animate({opacity:1}, 'slow', function() {
          $('.section-container-bg').removeClass('banner2').addClass('banner3');
        })
      })

    } else if($('.section-container').hasClass('banner2')) {

      $('.section-container').animate({opacity: 0}, 'slow', function() {
        $(this).removeClass('banner2').addClass('banner3').animate({opacity:1}, 'slow', function() {
          $('.section-container-bg').removeClass('banner3').addClass('banner1');
        })
      })

    } else if($('.section-container').hasClass('banner3')) {

      $('.section-container').animate({opacity: 0}, 'slow', function() {
        $(this).removeClass('banner3').addClass('banner1').animate({opacity:1}, 'slow', function() {
          $('.section-container-bg').removeClass('banner1').addClass('banner2');
        });
      })

    }
  }

  setInterval(function(){ rotateBanner(); }, 3000);

	// $(".wrap_desktop").camera({
 //      loader: "none",
 //      navigation: false,
 //      fx: 'scrollHorz',
 //      pagination: true,
 //      height:'700',
 //      alignment: 'center',
 //      autoAdvance: true,
 //      hover: false,
 //      playPause: false,
 //      time: 3000,
 //      alignment: 'center',
 //      // pauseOnClick: false
 //   });  

 //   $(".wrap_mobile").camera({
 //      loader: "none",
 //      navigation: false,
 //      fx: 'scrollHorz',
 //      pagination: true,
 //      alignment: 'center',
 //      autoAdvance: true,
 //      hover: false,
 //      playPause: false,
 //      time: 3000,
 //      alignment: 'center',
 //      // pauseOnClick: false
 //   }); 


 $(".menuitem a").click( function(e) {
  e.preventDefault();

  $(".menuitem").removeClass('active');
  $(this).parent().addClass('active');
 });

  if($(".sidebars").length != 0)
  {
    // All sides
    var sides = ["left", "top", "right", "bottom"];
    //$("h1 span.version").text($.fn.sidebar.version);
  
    // Initialize sidebars
    for (var i = 0; i < sides.length; ++i) {
        var cSide = sides[i];
        $(".sidebar." + cSide).sidebar({side: cSide});
    }

    // Click handlers
    $(".btn[data-action]").on("click", function () {
        var $this = $(this);
        var action = $this.attr("data-action");
        var side = $this.attr("data-side");
        // $(".sidebar." + side).css("display","block");
        $(".sidebar." + side).trigger("sidebar:" + action);
        return false;
    });

    $('a[href*=\\#]:not([href=\\#])').click(function() {
      $(".sidebar." + "right").trigger("sidebar:" + "toggle");
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') 
          || location.hostname == this.hostname) {

          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
             if (target.length) {
               $('html,body').animate({
                   scrollTop: target.offset().top -84
              }, 1000);
              return false;
          }
      }
  });




}
});